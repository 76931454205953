import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: "Download Magic Lamp" },
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: "Register for Magic Lamp" },
    component: () => import('../views/Register.vue')
  },
  {
    path: '/invite/:code',
    name: 'Invite',
    meta: { title: "Invite Registration" },
    component: () => import('../views/Invite.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router
