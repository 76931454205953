const env = process.env.NODE_ENV;

const Setting = {
    /**
     * 请求数据配置
     * */
    request: {
        // 接口请求地址
        apiBaseURL: env === 'development' ? 'https://api.magiclamp.club/api' : 'https://api.magiclamp.club/api',
        // 请求超时时间
        timeout: 5000,
        // 接口请求返回错误时，弹窗的持续时间，单位：秒
        modalDuration: 3,
        // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
        errorModalType: 'Message',
        // 请求拦截器配置
        requestConfig (config, util) {
            return {

            }
        }
    }
}

export default Setting;