import request from '@/request/index'

export function AppDownloadLink (data) {
    return request({
        url: '/app/download',
        method: 'post',
        data
    });
}

export function CheckInviteCode (data) {
    return request({
        url: '/invite/check',
        method: 'post',
        data
    });
}

export function OTPSMS (data) {
    return request({
        url: '/otp/send',
        method: 'post',
        data
    });
}

export function registerValidSMS (data) {
    return request({
        url: '/account/register/valid_sms',
        method: 'post',
        data
    });
}

export function MemberRegister (data) {
    return request({
        url: '/api/login',
        method: 'post',
        data
    });
}