<template>
  <div class="main">
    <div class="nav">
      <div class="nav-logo">
        <img src="../assets/logo.png" alt="logo">
      </div>
      <router-link class="router-link" to="/register">
      <view class="reg-btn">
        Register
      </view>
      </router-link>
    </div>
    <div class="content_bg"></div>
    <div class="content">
      <div class="big_title">
        Continue using the MagicLamp app
      </div>
      <div class="small_title">
        For security reasons, you need to continue using the MagicLamp app. Don't have MagicLamp installed yet? Click to download the MagicLamp Trial app.
      </div>
      <div class="download">
        <img src="../assets/down.png"  alt=""/>
      </div>
      <div class="down_btn" @click="goApp">
        Download Now
      </div>
      <div class="tips">
        Latest version number: {{ version }}
      </div>

      <div class="store_btns">
        <img src="../assets/app_store.png" alt=""/>
        <img src="../assets/google_play.png" alt=""/>
      </div>

      <div class="come_soon">
        Store Download Coming Soon
      </div>
    </div>
  </div>
</template>

<script>
import {AppDownloadLink} from "@/api/account";

export default {
  data(){
    return {
      url:'',
      version:''
    }
  },
  mounted() {
    this.getApp()
  },
  methods: {
    getApp(){
      this.$Spin.show();
      AppDownloadLink().then(res => {
        this.url = res.url;
        this.version = res.version;
        this.$Spin.hide();
        //console.log(res)
      })
    },
    goApp(){
      window.location.href = this.url;
    }
  }
}
</script>

<style scoped>
.router-link {
  color: #ffffff;
}
.main{
  height: auto;
  overflow: hidden;
}
.nav{
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 10px 10px rgba(50, 50, 50, 0.07);
  position: relative;
}
.nav-logo{
  width: 150px;
  float: left;
}
.nav-logo img{
  width: 100%;
  display: block;
}
.reg-btn{
  float: right;
  border: 1px solid #3D3D46;
  background-color: #3D3D46;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.reg-btn:hover{
  border: 1px solid #3D3D46;
  background-color: #ffffff;
  color: #3D3D46;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.content_bg{
  background-image: url("../assets/login_bg.jpg");
  min-height: 180px;
  background-size: auto 180px;
}
.content{
  height: auto;
  overflow: hidden;
  position: relative;
  margin-top: -80px;
}
.big_title{
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.small_title{
  max-width: 600px;
  text-align: center;
  margin: 30px auto 0;
  padding: 0 30px;
  color: #9395A9;
}
.download{
  width: 200px;
  margin: 50px auto 0;
}
.download img{
  width: 100%;
}
.down_btn{
  width: 200px;
  background-image: linear-gradient(to right, #EA3324, #EB4766);
  margin: 30px auto 0;
  border-radius: 50px;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
}
.down_btn:hover{
  background-image: linear-gradient(to right, #EB4766, #EA3324);
  cursor: pointer;
}
.tips{
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  color: #9395A9;;
}

.store_btns{
  width: 310px;
  margin: 30px auto 0;
  height: auto;
  overflow: hidden;
}

.store_btns img{
  width: 150px;
}
.store_btns img:nth-child(odd){
  float: left;
}
.store_btns img:nth-child(even){
  float: right;
}
.come_soon{
  text-align: center;
  color: #3D3D46;
  margin-top: 10px;
}
</style>
